@import "./vendor/normalize";

// стили для модального окна
@import "../../node_modules/graph-modal/dist/graph-modal.min";

// стили для табов
@import "../../node_modules/graph-tabs/dist/graph-tabs.min";

// стили для библиотеки aos
// @import "../../node_modules/aos/dist/aos";

// стили для свайпера
// @import "../../node_modules/swiper/swiper-bundle.min";

// Стили для jquery.fancytree
// @import "/skin-custom-tree/ui.fancytree.css";
@import "../skin-custom-tree/ui.fancytree.css";

// Стили для fancybox
@import "../../node_modules/@fancyapps/ui/dist/fancybox/fancybox";

// Стили для таблицы
@import "../../node_modules/datatables.net-dt/css/jquery.dataTables";

//@import "./vendor/chosen";

// Стили для Select2
@import "./vendor/select2/core";

// Стили DaData

@import "./vendor/dadata/_suggestions.scss";